import axios from 'axios';
import { isProd } from '../constants';

export const getFormFieldsType = async () => {
  const url = isProd
    ? `https://med-discrete-data.azurewebsites.net/api/getFieldsType?code=o6uZFne3RR1nrnvj-cQ2fhtf_uAJwL9qGuBqbupDRminAzFuYZaZEQ==`
    : 'https://br-apim.azure-api.net/med-discrete-data-uat/getFieldsType';

  try {
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    console.log('err', err);
  }
};

export const getFormNotesTemplate = async (sitecode) => {
  const url = isProd
    ? `https://med-discrete-data.azurewebsites.net/api/getFormTemplateBySitecode?code=OioCtiJpWqlkwVpwcbzqEDsS071fNjW-2L8QVfhEXkYeAzFurthIqg==&sitecode=${sitecode}`
    : `https://med-discrete-data-uat.azurewebsites.net/api/getFormTemplateBySitecode?code=IZHpYkUXzqTOmquCxQQq_0rCYIGaxyJTrQpJAsyMm6V2AzFuQIaIDg==&sitecode=${sitecode}`;
  let result = {};
  try {
    let res = await axios.get(url);
    if (res) result = res.data;
  } catch (err) {
    console.log('err', err);
  }
  return result;
};

import { create } from 'zustand';
import {
  searchPatients,
  fetchActiveCallsFromTable,
  fetchPatientExtendedNote,
  fetchAppointmentsMR,
  savePatientExtendedNote,
  savePatientPsoForm,
} from '../utils/fetchPatients';
import { safeParseJSON } from '../utils/jsonUtils';

const usePatientStore = create((set) => ({
  startFetchApptInterval: null,
  setStartFetchApptInterval: (fn) => set({ startFetchApptInterval: fn }),
  validPatients: [],
  allPatients: [],
  activeCalls: [],
  selectedPatient: null,
  patientExtendedNote: null,
  psoFormUpdated: false,
  onSelectPatient: (patient) => {
    set({ selectedPatient: patient });
  },
  fetchPatients: async ({
    dateTo,
    dateFrom,
    sitecode,
    patientFirstName,
    patientLastName,
    DOB,
    pattern = 'PARTIAL',
  }) => {
    set({
      isFetchingPatients: true,
      isFetchingPatientsError: false,
      isFetchingPatientsSuccess: false,
    });
    try {
      const res = await searchPatients({
        dateTo,
        dateFrom,
        sitecode,
        patientFirstName,
        patientLastName,
        DOB,
        pattern,
      });

      const uniquePatients = Object.values(
        res.reduce((acc, item) => {
          if (
            !acc[item.caseID] ||
            new Date(item.createTime) > new Date(acc[item.caseID].createTime)
          ) {
            acc[item.caseID] = item;
          }
          return acc;
        }, {})
      );

      const validPatientsRes = (uniquePatients || []).filter((patient) => {
        return patient.patientFirstName && patient.caseID;
      });
      set({
        validPatients: validPatientsRes,
        allPatients: uniquePatients,
        isFetchingPatientsSuccess: true,
      });
    } catch (err) {
      set({ isFetchingPatientsError: true, isFetchingPatientsSuccess: false });
      console.error(err);
    } finally {
      set({ isFetchingPatients: false, psoFormUpdated: false });
    }
  },
  fetchActiveCalls: async () => {
    set({
      isFetchingPatients: true,
      isFetchingPatientsError: false,
      isFetchingPatientsSuccess: false,
    });
    try {
      const res = await fetchActiveCallsFromTable();
      console.log('active calls', res);

      let activePatients = [];
      res?.forEach((call) => {
        if (call?.patients) {
          const patientsInCall = safeParseJSON(call.patients);
          if (!patientsInCall?.length || patientsInCall.length === 0) {
            activePatients.push({
              ...call,
              isMultiPatient: false,
              multiIndicator: false,
            });
          } else if (patientsInCall?.length === 1) {
            activePatients.push({
              ...call,
              ...patientsInCall[0],
              isMultiPatient: false,
              multiIndicator: false,
            });
          } else {
            const updatedPatientsInCall = patientsInCall.map((patient) => ({
              ...patient,
              multiIndicator: true,
            }));
            activePatients.push({
              ...call,
              children: updatedPatientsInCall,
              isMultiPatient: true,
              multiIndicator: true,
            });
          }
        } else {
          activePatients.push({
            ...call,
            isMultiPatient: false,
            multiIndicator: false,
          });
        }
      });
      console.log('activeCalls', activePatients);
      set({
        activeCalls: activePatients,
        isFetchingPatientsSuccess: true,
      });
    } catch (err) {
      set({ isFetchingPatientsError: true, isFetchingPatientsSuccess: false });
      console.error(err);
    } finally {
      set({ isFetchingPatients: false });
    }
  },
  fetchExtendedNote: async (caseID) => {
    set({
      isFetchingExtendedNote: true,
      isFetchingExtendedNoteSuccess: false,
      isFetchingExtendedNoteError: false,
    });
    try {
      const res = await fetchPatientExtendedNote(caseID);
      if (res.status === 200) {
        set({
          isFetchingExtendedNoteSuccess: true,
          isFetchingExtendedNoteError: false,
          patientExtendedNote: res.data,
        });
      } else {
        set({
          isFetchingExtendedNoteError: true,
          isFetchingExtendedNoteSuccess: false,
          patientExtendedNote: null,
        });
      }
    } catch (err) {
      set({
        isFetchingExtendedNoteError: true,
        isFetchingExtendedNoteSuccess: false,
      });
      console.error(err);
    } finally {
      set({ isFetchingExtendedNote: false });
    }
  },
  fetchMRAppointments: async () => {
    set({
      isFetchingMRAppointments: true,
      isFetchingMRAppointmentsSuccess: false,
      isFetchingMRAppointmentsError: false,
    });
    try {
      const res = await fetchAppointmentsMR();
      console.log('res', res);
      if (res) {
        set({
          isFetchingMRAppointmentsSuccess: true,
          isFetchingMRAppointmentsError: false,
          patientMRAppointments: res,
        });
      } else {
        set({
          isFetchingMRAppointmentsError: true,
          isFetchingMRAppointmentsSuccess: false,
        });
      }
    } catch (err) {
      set({
        isFetchingMRAppointmentsError: true,
        isFetchingMRAppointmentsSuccess: false,
      });
      console.error(err);
    } finally {
      set({ isFetchingMRAppointments: false });
    }
  },
  updatePsoForm: async (data) => {
    set({
      isSavingPatient: true,
      isSavingPatientSuccess: false,
      isSavingPatientError: false,
    });
    try {
      const res = await savePatientPsoForm(data);
      if (res.status === 200) {
        set({
          isSavingPatientSuccess: true,
          isSavingPatientError: false,
        });
      } else {
        set({ isSavingPatientError: true, isSavingPatientSuccess: false });
      }
    } catch (err) {
      set({ isSavingPatientError: true, isSavingPatientSuccess: false });
      console.error(err);
    } finally {
      set({ isSavingPatient: false });
    }
  },
  updateExtendedNote: async (data) => {
    set({
      isSavingExtendedNote: true,
      isSavingExtendedNoteSuccess: false,
      isSavingExtendedNoteError: false,
    });
    try {
      const res = await savePatientExtendedNote(data);
      if (res.status === 200) {
        set({
          isSavingExtendedNoteSuccess: true,
          isSavingExtendedNoteError: false,
        });
      } else {
        set({
          isSavingExtendedNoteError: true,
          isSavingExtendedNoteSuccess: false,
        });
      }
    } catch (err) {
      set({
        isSavingExtendedNoteError: true,
        isSavingExtendedNoteSuccess: false,
      });
      console.error(err);
    } finally {
      set({ isSavingExtendedNote: false });
    }
  },
  setPsoFormUpdated: () => {
    set({
      psoFormUpdated: true,
    });
  },
  clearPsoForm: () => {
    set({
      isSavingPatient: false,
      isSavingPatientError: false,
      isSavingPatientSuccess: false,
    });
  },
  clearExtendedNote: () => {
    set({
      patientExtendedNote: null,
      isFetchingExtendedNoteSuccess: false,
      isFetchingExtendedNoteError: false,
      isFetchingExtendedNote: false,
      isSavingExtendedNoteSuccess: false,
      isSavingExtendedNoteError: false,
      isSavingExtendedNote: false,
    });
  },
  isFetchingPatients: false,
  isFetchingPatientsError: false,
  isFetchingPatientsSuccess: false,
  isSavingPatient: false,
  isSavingPatientError: false,
  isSavingPatientSuccess: false,
  isFetchingExtendedNoteSuccess: false,
  isFetchingExtendedNoteError: false,
  isFetchingExtendedNote: false,
  isSavingExtendedNoteSuccess: false,
  isSavingExtendedNoteError: false,
  isSavingExtendedNote: false,
}));

export default usePatientStore;

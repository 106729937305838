import { create } from 'zustand';
import { getSitecodeList } from '../utils/fetchSitecodes';

const useFormStore = create((set) => ({
  psoFormUpdated: false,
  fetchSitecodeList: async () => {
    set({
      isFetchingSitecodeList: true,
    });

    try {
      const res = await getSitecodeList();
      set({
        sitecodeList: (res ?? []).map((sitecode) => ({
          label: sitecode,
          value: sitecode,
        })),
        isFetchingSitecodeListSuccess: true,
        isFetchingSitecodeListError: false,
      });
    } catch (err) {
      set({
        isFetchingSitecodeListError: true,
        isFetchingSitecodeListSuccess: false,
      });
      console.error(err);
    } finally {
      set({ isFetchingSitecodeList: false });
    }
  },
}));

export default useFormStore;

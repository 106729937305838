import axios from 'axios';
import { isProd } from '../constants';

export const searchPatients = async (filters) => {
  const url = isProd
    ? 'https://med-discrete-data.azurewebsites.net/api/searchPatients?code=u357w6IDDZH9jYJ43zbJGtXR92meEXEVZ7ggmmnHPVDBAzFuDzmh6Q=='
    : 'https://med-discrete-data-uat.azurewebsites.net/api/searchPatients?code=fmauB---H7eOustrnKCYE5tZGF0LKKOd-x_QADDB3KscAzFuNnRG0g==';

  try {
    let res = await axios.post(url, filters);
    return res.data;
  } catch (err) {
    console.error('err', err);
  }
};

export const savePatientPsoForm = async (data) => {
  const url = isProd
    ? 'https://med-discrete-data.azurewebsites.net/api/updateNotesByCallSid?code=IYy_lPnTxpbUgmnBhEuS-C24EIFRAcEtMV4c-q1yVWoAAzFuXvijDw=='
    : 'https://med-discrete-data-uat.azurewebsites.net/api/updateNotesByCallSid?code=sKbyHk5nYjUauM6SrvDEOJWyFt-W1fCZ7O1uPwAptpabAzFu59YmKw==';

  try {
    let res = await axios.post(url, data);
    return res;
  } catch (err) {
    console.error('err', err);
  }
};

export const fetchPatientExtendedNote = async (caseID) => {
  console.log('caseID', caseID);
  const url = isProd
    ? 'https://extended-note.azurewebsites.net/api/getExtendedNote?code=_g3RWf4i56hGQ7TmLqKRTDYgMxvRToGpLn_3yZtw8hykAzFupQIu1w=='
    : 'https://extended-note-uat.azurewebsites.net/api/getExtendedNote?code=3tKa-NeAgRrCkWvTdxkklnXaGg7RZFQjQO8NkA5SeR-hAzFuAQlzNw==';

  if (caseID) {
    try {
      let res = await axios.post(url, { caseID });
      return res;
    } catch (err) {
      console.error('err', err);
    }
  } else {
    throw new Error('caseID is required');
  }
};

export const savePatientExtendedNote = async (data) => {
  const { callSid } = data;
  console.log('callSid', callSid);
  const url = isProd
    ? 'https://extended-note.azurewebsites.net/api/updateExtendedNote?code=EsD5CeTJETs1zDGnFnG8fbmD6dGEhg9DtC-JbT0VL8PEAzFupjauVA=='
    : 'https://extended-note-uat.azurewebsites.net/api/updateExtendedNote?code=xrjVbOW-cPH9ONtkEtATEkry6ZksaT_SSKzMXgCTaEHBAzFuEa1aiA==';

  if (callSid) {
    try {
      let res = await axios.post(url, data);
      return res;
    } catch (err) {
      console.error('err', err);
    }
  } else {
    throw new Error('callSid is required');
  }
};

export const updateLinkCallByCaseID = async (data) => {
  const url = isProd
    ? 'https://med-discrete-data.azurewebsites.net/api/updateLinkCallByCaseID?code=th-vBf0O-IhScxgMi8mQywSQ9rzvqjCKtWg8YH9aAJp_AzFuSAqLeA=='
    : 'https://med-discrete-data-uat.azurewebsites.net/api/updateLinkCallByCaseID?code=5jFjrCjDb_xmugamwRbrlJ-lcUr_Ddqyd5mBnsnV4AvwAzFujXIy_g%3D%3D';

  const { caseIDOriginal } = data;
  if (caseIDOriginal) {
    try {
      let res = await axios.post(url, data);
      return res.data;
    } catch (err) {
      console.error('err', err);
    }
  } else {
    throw new Error('caseID is required');
  }
};

export const fetchActiveCallsFromTable = async () => {
  const url = isProd
    ? 'https://med-discrete-data.azurewebsites.net/api/getActiveCalls?code=uj4JpGc2FixqELBtV2ONI8CRTyuYxx7W5dDmDNLWGgkmAzFut-OefQ=='
    : 'https://med-discrete-data-uat.azurewebsites.net/api/getActiveCalls?code=eAd-zwgve6xSFibFYHFMp3eDCEpSP9LRSh0zMCUrXbcNAzFuiWWt8w==';

  try {
    let res = await axios.get(url);
    return res.data;
  } catch (err) {
    console.error('err', err);
  }
};

export const fetchAppointmentsMR = async () => {
  const url = isProd
    ? 'https://med-discrete-data.azurewebsites.net/api/getMediRecordAppointments?code=hH96zfGZcwZg7caZ44zw6faoAfpbx65mkN1SVLEyvko6AzFuv1HzSg=='
    : 'https://med-discrete-data-uat.azurewebsites.net/api/getMediRecordAppointments?code=77cjPmIDJHljOaE2s81dFkoVAn94t5l9C5K1MFhcT0XkAzFulCWCKw==';

  try {
    let res = await axios.get(url);
    return res.data;
  } catch (err) {
    console.error('err', err);
  }
};

import React from 'react';
import { message } from 'antd';
import { usePatientStore } from '../store';
import Header from './Layout/Header';
import PatientTable from './Table/PatientTable';
import ModalService from './Modal/ModalService';

const PSOPanel = ({ userInfo }) => {
  const [messageApi, messagePlaceholder] = message.useMessage();

  const { isFetchingPatientsError } = usePatientStore((state) => ({
    isFetchingPatientsError: state.isFetchingPatientsError,
  }));

  return (
    <>
      {messagePlaceholder}
      <Header />
      {isFetchingPatientsError ? (
        <div>
          Error: Failed to get call information, please try again or contact our
          technical support.
        </div>
      ) : (
        <>
          <ModalService messageApi={messageApi} />
          <PatientTable userInfo={userInfo} messageApi={messageApi} />
        </>
      )}
    </>
  );
};

export default PSOPanel;

import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Checkbox, DatePicker, Button, Select } from 'antd';
import { isEmpty } from 'lodash';
import {
  INVALID_COMPARE_TIME_HELPER,
  INVALID_TIME_HELPER,
} from '../../constants';
import { contactNumberRegex } from '../../regex';
import dayjs from 'dayjs';

const defaultState = {
  secondaryNumber: '',
  callbackNum: '',
  cbNotes: '',
  cbTime: '',
  stopWaitTime: '',
  startWaitTime: '',
  consultStart: '',
  stopTime: '',
  dnb: false,
  dnbReason: '',
  nc: false,
  ncReason: '',
};

const dnbDropdownList = [
  {
    value: '',
    label: 'None',
  },
  {
    value: 'CC',
    label: 'Consult incomplete',
  },
  {
    value: 'CS',
    label: 'Complimentary Service',
  },
  {
    value: 'PO',
    label: 'Promotional Offer',
  },
];

const ncDropdownList = [
  {
    value: '',
    label: 'None',
  },
  {
    value: 'CR',
    label: 'Patient cannot be reach',
  },
  {
    value: 'CP',
    label: 'Patient cancelled',
  },
  {
    value: 'ND',
    label: 'Connection Issue',
  },
];

const extendedNotesFields = [
  {
    id: 'secondaryNumber',
    label: 'Secondary Contact Number',
    type: 'text',
    value: 'secondaryNumber',
  },
  {
    id: 'callbackNum',
    label: 'Callback Number',
    type: 'text',
    value: 'callbackNum',
  },
  {
    id: 'cbNotes',
    label: 'Callback Note',
    type: 'text',
    value: 'cbNotes',
  },
  {
    id: 'cbTime',
    label: 'Callback Time',
    type: 'time',
    value: 'cbTime',
  },
  {
    id: 'stopWaitTime',
    label: 'Stop Wait Time',
    type: 'time',
    value: 'stopWaitTime',
  },
  {
    id: 'startWaitTime',
    label: 'Start Wait Time',
    type: 'time',
    value: 'startWaitTime',
  },
  {
    id: 'consultStart',
    label: 'Dr Attended Time (Consult Start)',
    type: 'time',
    value: 'consultStart',
  },
  {
    id: 'stopTime',
    label: 'Consult End Time (Stop Time)',
    type: 'time',
    value: 'stopTime',
  },
  {
    id: 'dnb',
    label: 'Do not Bill (DNB)',
    type: 'checkbox',
    value: 'dnb',
  },
  {
    id: 'dnbReason',
    label: 'List of Reason',
    type: 'dropdown',
    value: 'dnbReason',
  },
  {
    id: 'nc',
    label: 'No Consult (NC)',
    type: 'checkbox',
    value: 'nc',
  },
  {
    id: 'ncReason',
    label: 'List of Reason',
    type: 'dropdown',
    value: 'ncReason',
  },
];

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 10,
  },
};

const renderFields = ({
  type,
  id,
  label,
  value,
  handleNotesChange,
  viewOnly = false,
  // note is the extended note object
  note = {},
  error,
}) => {
  switch (type) {
    case 'text': {
      let placeholder = '';
      let helperText = '';
      if (id === 'secondaryNumber' || id === 'callbackNum') {
        placeholder = 'Please enter 10 digit number';
        helperText = 'e.g. 04xx xxx xxx or 02 xxxx xxxx';
      }
      return (
        <Form.Item
          label={label}
          colon={false}
          layout="horizontal"
          labelWrap={true}
          validateStatus={error ? 'error' : ''}
          help={error || helperText}
          size="large"
        >
          <Input
            disabled={viewOnly}
            variant="outlined"
            placeholder={placeholder}
            value={value}
            size="small"
            onChange={(e) => handleNotesChange(e.target.value, id)}
          />
        </Form.Item>
      );
    }
    case 'dropdown': {
      let disable = false;
      if (id === 'dnbReason') {
        disable = note?.dnb === false;
      } else if (id === 'ncReason') {
        disable = note?.nc === false;
      }
      return (
        <Form.Item
          label={label}
          colon={false}
          layout="horizontal"
          labelWrap={true}
          value={value}
          size="small"
        >
          <Select
            onChange={(value) => handleNotesChange(value, id)}
            options={id === 'dnbReason' ? dnbDropdownList : ncDropdownList}
            disabled={disable || viewOnly}
            size="small"
          />
        </Form.Item>
      );
    }

    case 'checkbox':
      return (
        <Form.Item
          label={label}
          colon={false}
          layout="horizontal"
          labelWrap={true}
          size="small"
        >
          <Checkbox
            color="primary"
            checked={value}
            onChange={(e) => handleNotesChange(e.target.checked, id)}
            disabled={viewOnly}
            size="small"
          />
        </Form.Item>
      );
    case 'time':
      return (
        <Form.Item
          label={label}
          colon={false}
          layout="horizontal"
          labelWrap={true}
          size="small"
          validateStatus={error ? 'error' : ''}
          help={error}
        >
          <DatePicker
            size="small"
            value={value ? dayjs(value) : null}
            disabled={viewOnly}
            showTime={{
              format: 'HH:mm',
            }}
            onChange={(_, dateString) => {
              handleNotesChange(dateString, id);
            }}
          />
        </Form.Item>
      );
    default:
      return (
        <Form.Item
          label={label}
          name={id}
          colon={false}
          layout="horizontal"
          labelWrap={true}
          validateStatus={error ? 'error' : ''}
          help={error}
          size="small"
        >
          <Input
            disabled={viewOnly}
            variant="outlined"
            value={value}
            size="small"
            onChange={(e) => handleNotesChange(e.target.value, id)}
          />
        </Form.Item>
      );
  }
};

const ExtendedNotes = ({
  messageApi,
  setUnsaved,
  clearExtendedNote,
  selectedPatient,
  patientExtendedNote,
  updateExtendedNote,
  isSavingExtendedNoteSuccess,
  isSavingExtendedNoteError,
  isSavingExtendedNote,
}) => {
  const extendedNotesUnavailableRef = useRef(isEmpty(patientExtendedNote));
  const [notes, setNotes] = useState(() => {
    let res = Object.keys(patientExtendedNote ?? {}).reduce(
      (acc, key) => {
        if (key in defaultState) {
          acc[key] =
            patientExtendedNote[key] !== null
              ? patientExtendedNote[key]
              : defaultState[key];
        }
        return acc;
      },
      { ...defaultState }
    );

    res = {
      ...res,
      cbTime: res.cbTime ? dayjs(res.cbTime).format('YYYY-MM-DD HH:mm:ss') : '',
      stopWaitTime: res.stopWaitTime
        ? dayjs(res.stopWaitTime).format('YYYY-MM-DD HH:mm:ss')
        : '',
      startWaitTime: res.startWaitTime
        ? dayjs(res.startWaitTime).format('YYYY-MM-DD HH:mm:ss')
        : '',
      consultStart: res.consultStart
        ? dayjs(res.consultStart).format('YYYY-MM-DD HH:mm:ss')
        : '',
      stopTime: res.stopTime
        ? dayjs(res.stopTime).format('YYYY-MM-DD HH:mm:ss')
        : '',
    };
    return res;
  });
  const [updated, setUpdated] = useState(false);
  const [viewOnly, setViewOnly] = useState(
    !extendedNotesUnavailableRef.current
  );
  const [errorMsg, setErrorMsg] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    return () => {
      clearExtendedNote();
    };
  }, [clearExtendedNote]);

  useEffect(() => {
    if (isSubmitted) {
      validateForm(notes);
    }
  }, [notes, isSubmitted]);

  useEffect(() => {
    // empty extended note
    if (extendedNotesUnavailableRef.current) {
      // already saved
      if (viewOnly === true) {
        setUnsaved((prev) => ({ ...prev, extendedNote: false }));
        // not saved, not sure if modified
      } else if (viewOnly === false) {
        console.log('notes', notes);
        // modified but not saved
        if (updated) {
          setUnsaved((prev) => ({ ...prev, extendedNote: true }));
          // modified and saved
        } else {
          setUnsaved((prev) => ({ ...prev, extendedNote: false }));
        }
      }
    } else {
      if (viewOnly === true) {
        setUnsaved((prev) => ({ ...prev, extendedNote: false }));
      } else if (viewOnly === false && !extendedNotesUnavailableRef.current) {
        setUnsaved((prev) => ({ ...prev, extendedNote: true }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewOnly, setUnsaved, updated]);

  useEffect(() => {
    if (
      !isSavingExtendedNote &&
      isSavingExtendedNoteSuccess &&
      !isSavingExtendedNoteError
    ) {
      messageApi.open({
        type: 'success',
        content: 'Successful save extended notes',
        duration: 1,
        key: 'saveExtendedNotesSuccess',
        onClick: () => {
          messageApi.destroy('saveExtendedNotesSuccess');
        },
      });
      setViewOnly(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSavingExtendedNote,
    isSavingExtendedNoteSuccess,
    isSavingExtendedNoteError,
  ]);

  const handleNotesChange = (value, name) => {
    if (!updated) setUpdated(true);
    setNotes((prev) => {
      const newNotes = { ...prev };
      newNotes[name] = value;
      return newNotes;
    });
  };

  const handleEditClick = () => {
    setViewOnly(false);
    setIsSubmitted(false);
  };

  const validateForm = (noteData) => {
    let validationRes = true;
    const {
      secondaryNumber,
      callbackNum,
      cbTime,
      stopWaitTime,
      startWaitTime,
      consultStart,
      stopTime,
    } = noteData;
    if (
      secondaryNumber.replace(/ /g, '') !== '' &&
      !contactNumberRegex.test(secondaryNumber.replace(/ /g, ''))
    ) {
      setErrorMsg((prev) => ({
        ...prev,
        secondaryNumber: 'Please enter a valid phone number',
      }));
      validationRes = false;
    } else {
      setErrorMsg((prev) => ({
        ...prev,
        secondaryNumber: '',
      }));
    }
    if (
      callbackNum.replace(/ /g, '') !== '' &&
      !contactNumberRegex.test(callbackNum.replace(/ /g, ''))
    ) {
      setErrorMsg((prev) => ({
        ...prev,
        callbackNum: 'Invalid contact number',
      }));
      validationRes = false;
    } else {
      setErrorMsg((prev) => ({ ...prev, callbackNum: '' }));
    }
    const callbackT = dayjs(cbTime);
    const stopWaitT = dayjs(stopWaitTime);
    const startWaitT = dayjs(startWaitTime);
    const consultStartT = dayjs(consultStart);
    const consultEndT = dayjs(stopTime);
    const currentT = dayjs(new Date());
    if (
      cbTime ||
      stopWaitTime ||
      startWaitTime ||
      consultStart ||
      stopTime ||
      consultStart ||
      stopWaitTime
    ) {
      const consultTimeInvalid =
        consultStart && stopTime && consultStartT.isAfter(consultEndT);
      const waitTimeInvalid =
        stopWaitTime && startWaitTime && stopWaitT.isAfter(startWaitT);

      const targetErrorMessage = {
        cbTime:
          cbTime && callbackT.isAfter(currentT) ? INVALID_TIME_HELPER : '',

        stopWaitTime: waitTimeInvalid
          ? INVALID_COMPARE_TIME_HELPER
          : stopWaitTime && stopWaitT.isAfter(currentT)
            ? INVALID_TIME_HELPER
            : '',

        startWaitTime: waitTimeInvalid
          ? INVALID_COMPARE_TIME_HELPER
          : startWaitTime && startWaitT.isAfter(currentT)
            ? INVALID_TIME_HELPER
            : '',

        consultStart: consultTimeInvalid
          ? INVALID_COMPARE_TIME_HELPER
          : consultStart && consultStartT.isAfter(currentT)
            ? INVALID_TIME_HELPER
            : '',

        stopTime: consultTimeInvalid
          ? INVALID_COMPARE_TIME_HELPER
          : stopTime && consultEndT.isAfter(currentT)
            ? INVALID_TIME_HELPER
            : '',
      };

      validationRes =
        validationRes &&
        !targetErrorMessage.cbTime &&
        !targetErrorMessage.stopWaitTime &&
        !targetErrorMessage.startWaitTime &&
        !targetErrorMessage.consultStart &&
        !targetErrorMessage.stopTime;
      setErrorMsg((prev) => ({ ...prev, ...targetErrorMessage }));
    }

    return validationRes;
  };

  const handleSaveClick = async () => {
    setIsSubmitted(true);
    const validationSuccess = validateForm(notes);
    if (validationSuccess) {
      await saveNote();
    }
  };
  const saveNote = async () => {
    const caseID = selectedPatient.caseID;
    const [callSid, patientIndex] = caseID.split('-');
    // data cleanup
    const note = {
      ...notes,
      secondaryNumber: notes.secondaryNumber?.replace(/ /g, '') || '',
      callbackNum: notes.callbackNum?.replace(/ /g, '') || '',
      cbTime: notes.cbTime,
      stopWaitTime: notes.stopWaitTime,
      startWaitTime: notes.startWaitTime,
      consultStart: notes.consultStart,
      stopTime: notes.stopTime,
    };
    await updateExtendedNote({ ...note, callSid, patientIndex });
  };

  return (
    <div>
      <Form {...formItemLayout}>
        {extendedNotesFields.map(({ label, id, value, type }) => (
          <div key={id}>
            {renderFields({
              id: id,
              label: label,
              type: type,
              handleNotesChange,
              value: notes[value],
              note: notes,
              viewOnly,
              error: errorMsg[id],
            })}
          </div>
        ))}
      </Form>
      {viewOnly ? (
        <Button onClick={handleEditClick}>Edit</Button>
      ) : (
        <Button
          type="primary"
          loading={isSavingExtendedNote}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      )}
      {isSavingExtendedNoteError && (
        <span>{`There's an error during Extended Notes submission, please fill in this form manually.`}</span>
      )}
    </div>
  );
};

export default ExtendedNotes;

import React, { useState } from 'react';
import { Modal, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useModalStore } from '../../store';
import { MODAL_TYPES, MODAL_TITLE } from '../../constants';
import PatientForm from '../PatientForm/PatientForm';

const UNSAVED_DESCRIPTION =
  'You have unsaved changes. Are you sure you want to close?';

const ModalService = ({ messageApi }) => {
  const { modalStatus, resetModal } = useModalStore((state) => ({
    modalStatus: state.modalStatus,
    updateModalStatus: state.updateModalStatus,
    resetModal: state.resetModal,
  }));
  const [unsaved, setUnsaved] = useState({
    psoForm: false,
    extendedNote: false,
  });
  const { open, type } = modalStatus;
  return (
    <Modal
      title={type in MODAL_TYPES ? `${MODAL_TITLE[type]}` : ''}
      width={800}
      open={open}
      keyboard={false}
      maskClosable={false}
      closeIcon={
        !unsaved.psoForm && !unsaved.extendedNote ? (
          <CloseOutlined onClick={resetModal} />
        ) : (
          <Popconfirm
            title="Close"
            description={UNSAVED_DESCRIPTION}
            onConfirm={resetModal}
            okText="Yes"
            cancelText="No"
          >
            <CloseOutlined />
          </Popconfirm>
        )
      }
      destroyOnClose={true}
      footer={null}
    >
      {type === MODAL_TYPES.PATIENT_FORM && (
        <PatientForm messageApi={messageApi} setUnsaved={setUnsaved} />
      )}
    </Modal>
  );
};

export default ModalService;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Form, AutoComplete, Input, Switch } from 'antd';
import { has, throttle } from 'lodash';
import { MANDATORY_STRING, OPTIONAL_STRING } from '../../constants';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const getOptions = (options) => {
  return options.map((option) => ({
    label: option === 'string' ? option : option.description,
    value: option === 'string' ? option : option.description,
  }));
};

const AddressField = ({ handleChange, errorMessage, isReadOnly, form }) => {
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [checked, setChecked] = useState(true);
  const loaded = useRef(false);

  const handleTextChange = (e) => {
    if (!isReadOnly) {
      handleChange(e, 'address');
      setValue(e.target.value);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&loading=async&libraries=places`,
          document.querySelector('head'),
          'google-maps'
        );
      }
      loaded.current = true;
    }
  }, []);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        request.componentRestrictions = { country: 'au' };
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, autocompleteService.current]);

  useEffect(() => {
    if (isReadOnly && form['address']) {
      setValue(form['address']);
    }
  }, [form['address'], isReadOnly]);

  useEffect(() => {
    if (!isReadOnly) {
      setValue('');
      handleChange('', 'address');
    }
  }, [checked]);

  useEffect(() => {
    const formAddress = form['address'];
    const hasPlaceholder =
      formAddress?.includes(MANDATORY_STRING) ||
      formAddress?.includes(OPTIONAL_STRING);

    if (inputValue !== form['address']) {
      if (hasPlaceholder) {
        setInputValue(formAddress?.split('/')?.[1] ?? '');
      } else {
        setInputValue(form['address']);
      }
    }
    if (value !== form['address']) {
      if (hasPlaceholder) {
        setValue(formAddress?.split('/')?.[1] ?? '');
      } else {
        setValue(form['address']);
      }
      setOptions([]);
    }
  }, [form['address']]);

  return (
    <Form.Item
      label="Add a location"
      colon={false}
      labelWrap={true}
      help={has(errorMessage, 'address') ? errorMessage['address'] : ''}
      validateStatus={has(errorMessage, 'address') ? 'error' : ''}
      // status={has(errorMessage, 'address')}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {checked ? (
          <AutoComplete
            options={getOptions(options)}
            value={inputValue}
            disabled={isReadOnly ? true : false}
            onChange={(newValue) => {
              setInputValue(newValue);
            }}
            onSelect={(newValue) => {
              if (!isReadOnly) {
                setValue(newValue);
                handleChange(newValue, 'address');
              }
            }}
            onBlur={() => {
              const optionsValue = options.map((option) => option.description);
              if (!optionsValue.includes(inputValue)) {
                setValue('');
                setInputValue('');
                handleChange('', 'address');
              }
            }}
            style={{ marginRight: 20, width: '90%' }}
          />
        ) : (
          <Input
            id="standard-basic"
            variant="outlined"
            value={inputValue}
            disabled={isReadOnly ? true : false}
            placeholder="10 Bond Street, Sydney NSW"
            onChange={(e) => handleTextChange(e)}
            style={{ marginRight: 20, width: '90%' }}
          />
        )}
        <Switch
          size="small"
          checked={checked}
          onChange={() => setChecked(!checked)}
          disabled={isReadOnly ? true : false}
          color="primary"
          name="address_toggle"
        />
      </div>
    </Form.Item>
  );
};

export default AddressField;

import { create } from 'zustand';
import dayjs from 'dayjs';
import { getSitecodeList } from '../utils/fetchSitecodes';

const defaultFilters = {
  patientFirstName: '',
  patientLastName: '',
  DOB: null,
  dateRange: [
    dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ],
  sitecode: '',
  callsType: 'active',
};

const useFilterStore = create((set) => ({
  filters: defaultFilters,
  updateFilters: (key, value) =>
    set((state) => ({ filters: { ...state.filters, [key]: value } })),

  sitecodeList: [],
  isFetchingSitecodeList: false,
  isFetchingSitecodeListSuccess: false,
  isFetchingSitecodeListError: false,
  fetchSitecodeList: async () => {
    set({
      isFetchingSitecodeList: true,
    });

    try {
      const res = await getSitecodeList();
      set({
        sitecodeList: (res ?? []).map((sitecode) => ({
          label: sitecode,
          value: sitecode,
        })),
        isFetchingSitecodeListSuccess: true,
        isFetchingSitecodeListError: false,
      });
    } catch (err) {
      set({
        isFetchingSitecodeListError: true,
        isFetchingSitecodeListSuccess: false,
      });
      console.error(err);
    } finally {
      set({ isFetchingSitecodeList: false });
    }
  },

  clearFilters: () =>
    set({
      filters: defaultFilters,
    }),
}));

export default useFilterStore;

import { startCase } from 'lodash';
import dayjs from 'dayjs';
import axios from 'axios';
import { getFormFieldsType, getFormNotesTemplate } from './fetchFormSettings';
import {
  isProd,
  GENERAL_PSO_FORM_FIELDS,
  CUSTOM_FIELDS,
  MANDATORY_STRING,
} from '../constants';

export const setUpKeys = (
  form,
  keys,
  setGeneralDropdownKeys,
  setDropdownKeys,
  setTextKeys,
  setBigTextKeys,
  classifiedFields
) => {
  let dropdownArr = keys.filter((key) => {
    return classifiedFields['dropdowns'].includes(key) && form[key];
  });
  let textArr = keys.filter((key) => {
    return classifiedFields['texts'].includes(key) && form[key];
  });
  let bigTextArr = keys.filter((key) => {
    return classifiedFields['bigTexts'].includes(key) && form[key];
  });

  const generalDropdownKeys = dropdownArr
    .filter((key) => GENERAL_PSO_FORM_FIELDS.includes(key))
    .reduce((acc, rec, index) => {
      if (index % 2 === 0) {
        return [...acc, [rec]];
      }
      acc[acc.length - 1] = [...acc[acc.length - 1], rec];
      return acc;
    }, []);

  const dropdownKeys = dropdownArr
    .filter((key) => !GENERAL_PSO_FORM_FIELDS.includes(key))
    .reduce((acc, rec, index) => {
      if (index % 2 === 0) {
        return [...acc, [rec]];
      }
      acc[acc.length - 1] = [...acc[acc.length - 1], rec];
      return acc;
    }, []);

  const textKeys = textArr.reduce((acc, rec, index) => {
    if (index % 2 === 0) {
      return [...acc, [rec]];
    }
    acc[acc.length - 1] = [...acc[acc.length - 1], rec];
    return acc;
  }, []);
  setBigTextKeys(bigTextArr);
  setDropdownKeys(dropdownKeys);
  setGeneralDropdownKeys(generalDropdownKeys);
  setTextKeys(textKeys);
};

export const getClassifiedFields = async (
  sitecodeList,
  fixedFieldTypes,
  setFixedFieldTypes,
  customUITypes = {},
  setClassifiedFields,
  setDropdownList
) => {
  try {
    let templateFieldTypes;
    if (fixedFieldTypes) {
      templateFieldTypes = fixedFieldTypes;
    } else {
      const res = await getFormFieldsType();
      setFixedFieldTypes(res);
      templateFieldTypes = res;
    }
    const fieldTypes = { ...templateFieldTypes, ...customUITypes };
    let obj = {
      texts: [],
      bigTexts: [],
      dropdowns: [],
      types: { ...fieldTypes },
    };
    let textFields = ['text', 'date', 'number'];
    Object.keys(fieldTypes).forEach((key) => {
      if (textFields.includes(fieldTypes[key]['uiType'])) {
        obj = Object.assign(
          {},
          {
            ...obj,
            texts: [...obj['texts'], key],
          }
        );
      } else if (fieldTypes[key]['uiType'] === 'dropdown') {
        obj = Object.assign(
          {},
          {
            ...obj,
            dropdowns: [...obj['dropdowns'], key],
          }
        );
      } else if (fieldTypes[key]['uiType'] === 'bigText') {
        obj = Object.assign(
          {},
          {
            ...obj,
            bigTexts: [...obj['bigTexts'], key],
          }
        );
      }
    });
    setClassifiedFields(obj);
    let dropDownObj = {};
    Object.keys(fieldTypes).forEach((key) => {
      if (fieldTypes[key]['fieldsInDropdown']) {
        dropDownObj[key] = ['-Select-', ...fieldTypes[key]['fieldsInDropdown']];
      }
    });
    // let sitecodes = [];
    // if (Array.isArray(sitecodeList) && sitecodeList.length > 0) {
    //   sitecodes = sitecodeList.map((code) => code.value);
    // }
    setDropdownList({
      ...dropDownObj,
      sitecode: [...sitecodeList],
    });
  } catch (error) {
    console.log('err calling getFormFieldsType', error);
  }
};

const getCustomizedFieldsHelper = (template) => {
  const {
    clientFieldOnOff1,
    clientFieldLabel1,
    clientDiscreteName1,
    clientFieldType1,
    clientFieldNature1,
    clientFieldUiType1,
    clientFieldDropdownValue1,
    clientFieldOnOff2,
    clientFieldLabel2,
    clientDiscreteName2,
    clientFieldType2,
    clientFieldNature2,
    clientFieldUiType2,
    clientFieldDropdownValue2,
    clientFieldOnOff3,
    clientFieldLabel3,
    clientDiscreteName3,
    clientFieldType3,
    clientFieldNature3,
    clientFieldUiType3,
    clientFieldDropdownValue3,
    clientFieldOnOff4,
    clientFieldLabel4,
    clientDiscreteName4,
    clientFieldType4,
    clientFieldNature4,
    clientFieldUiType4,
    clientFieldDropdownValue4,
    clientFieldOnOff5,
    clientFieldLabel5,
    clientDiscreteName5,
    clientFieldType5,
    clientFieldNature5,
    clientFieldUiType5,
    clientFieldDropdownValue5,
    ...restTemplate
  } = template;

  const customizedFields = {};
  const customizedUITypes = {};
  CUSTOM_FIELDS.forEach((customField) => {
    if (template[customField.status] === 'Y') {
      const fieldDiscreteName = template[customField.discreteName];
      const fieldDropdownValue = template[customField.dropdownValue] ?? '';
      const fieldDefaultValue = template[customField.nature];
      const fieldUIType = template[customField.type];
      const fieldLabel = template[customField.label];

      customizedFields[fieldDiscreteName] = fieldDefaultValue;

      customizedUITypes[fieldDiscreteName] = {
        uiType: fieldUIType,
        fieldLabel,
        fieldsInDropdown:
          fieldUIType === 'dropdown'
            ? fieldDropdownValue?.split(',') ?? null
            : null,
      };
    }
  });
  return { restTemplate, customizedFields, customizedUITypes };
};

export const getTemplate = async ({
  selectedSitecode,
  setRequiredFields,
  setTemplate,
  setForm,
  setCustomUITypes,
  setFormMessage,
  setFormLoading,
}) => {
  if (selectedSitecode) {
    if (setFormLoading) setFormLoading(true);
    const res = await getFormNotesTemplate(selectedSitecode, setFormMessage);

    // separate template fields and customized fields, inject into existing fields, modify UI types
    const { restTemplate, customizedFields, customizedUITypes } =
      getCustomizedFieldsHelper(res);
    setCustomUITypes(customizedUITypes);
    // inject UI types into field types
    const injectedTemplate = { ...restTemplate, ...customizedFields };
    const requiredKeys = Object.keys(injectedTemplate).filter(
      //field which is mandatory and no default value is required field
      (key) =>
        injectedTemplate[key] &&
        injectedTemplate[key].includes(MANDATORY_STRING)
    );

    let newTemplateFields = {};
    Object.keys(injectedTemplate).forEach((key) => {
      if (
        injectedTemplate[key] &&
        (injectedTemplate[key].includes(MANDATORY_STRING) ||
          injectedTemplate[key].includes('YES/')) &&
        injectedTemplate[key].split('/')[1]
      ) {
        newTemplateFields[key] = injectedTemplate[key].split('/')[1];
      } else {
        newTemplateFields[key] = injectedTemplate[key];
      }
      if (
        injectedTemplate[key] &&
        (injectedTemplate[key].includes(MANDATORY_STRING) ||
          injectedTemplate[key].includes('YES/')) &&
        key === 'triageCAT'
      ) {
        newTemplateFields[key] =
          injectedTemplate[key].split('/')[1] || injectedTemplate[key];
      }
    });

    let obj = Object.assign(
      {},
      {
        ...newTemplateFields,
        sitecode: selectedSitecode,
      }
    );
    setRequiredFields(requiredKeys);
    setTemplate(obj);
    setForm((prev) => ({ ...prev, sitecode: selectedSitecode }));
    setTimeout(() => {
      if (setFormLoading) setFormLoading(false);
    }, 1000);
  }
};

export const verifyForm = (
  isSubmitClicked,
  requiredFields,
  form,
  handleErrorMessage
) => {
  let errors = {};
  const undoneFields = requiredFields.filter(
    (key) =>
      (form[key] && form[key].toString().includes('MANDATORY')) ||
      form[key] === '' ||
      form[key] === null ||
      form[key] === '-Select-'
  );
  if (isSubmitClicked && undoneFields.length) {
    undoneFields.forEach((field) => {
      errors[field] = `${startCase(field)} is required`;
    });
  }
  if (
    isSubmitClicked &&
    form['medicareDetails'] &&
    !form['medicareDetails'].includes('YES/') &&
    form['medicareDetails'].toString().length !== 11
  ) {
    errors['medicareDetails'] =
      'Please enter all 11 digits of the Medicare number';
  }
  if (
    isSubmitClicked &&
    form['DOB'] &&
    !form['DOB'].includes(MANDATORY_STRING) &&
    (form['DOB'].toString().length !== 10 ||
      form['DOB'].split('-')[0] < 1900 ||
      !dayjs(form['DOB']).isBefore(dayjs()))
  ) {
    errors['DOB'] = 'Please enter a valid date';
  }
  handleErrorMessage(errors);
  return errors;
};

export const openMedirecords = async (
  data,
  mediRecordsDoctor = null,
  setMedirecordMessage,
  setLoading,
  setIsEditBtnReadOnly,
  setIsAppointmentBtnClicked
) => {
  try {
    setLoading(true);
    setIsEditBtnReadOnly(true);
    const url = isProd
      ? 'https://medmedirecordsapis.azurewebsites.net/api/createPatient'
      : 'https://medmedirecordsapisuat.azurewebsites.net/api/createPatient';
    const res = await axios.post(url, { ...data, mediRecordsDoctor });
    if (res) {
      setIsAppointmentBtnClicked(true);
      setLoading(false);
    }
    if (res.data.deeplink) {
      window.open(
        `${res.data.deeplink}`,
        'popup',
        'location=0,width=1200,height=600,left=300,top=5'
      );
    } else {
      setMedirecordMessage(res.data.message);
    }
  } catch (error) {
    setLoading(false);
    setIsAppointmentBtnClicked(true);
    setMedirecordMessage('Create patient failed, Please do it manually!');
  }
};
export const getContactDetails = async (sitecode, setContactDetails) => {
  try {
    const url = isProd
      ? `https://med-discrete-data.azurewebsites.net/api/getContactDetailsBySitecode?code=w5ipDhXEkJvPJ2zIwnJlwuE9j0DFKbp225NCvA8pmjj0AzFuhUj3_Q==&sitecode=${sitecode}`
      : `https://med-discrete-data-uat.azurewebsites.net/api/getContactDetailsBySitecode?code=_yHhbozt3GjZprY-AradgZLTGyaz2Y7a01CIPjBp_PSbAzFuaLu2zw==&sitecode=${sitecode}`;
    let res = await axios.get(url);
    setContactDetails(res.data);
  } catch (err) {
    console.log('err', err);
  }
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  Divider,
  Table,
  Space,
  Button,
  Tooltip,
  Dropdown,
  Tag,
  Typography,
} from 'antd';
import {
  FormOutlined,
  LinkOutlined,
  DownOutlined,
  PhoneOutlined,
  VideoCameraOutlined,
  DeploymentUnitOutlined,
  LineOutlined,
} from '@ant-design/icons';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import AppointmentTable from './AppointmentTable';
import { usePatientStore, useModalStore, useFilterStore } from '../../store';
import LinkPatientModal from '../Modal/LinkPatientModal';
import { MODAL_TYPES } from '../../constants';
import { parseFieldSetup, translateStatus } from '../../utils/stringUtils';

dayjs.extend(utc);
const { Text } = Typography;

const PatientTable = ({ userInfo, messageApi }) => {
  const {
    activeCalls,
    validPatients,
    fetchActiveCalls,
    isFetchingPatients,
    onSelectPatient,
    selectedPatient,
  } = usePatientStore((state) => ({
    activeCalls: state.activeCalls,
    fetchActiveCalls: state.fetchActiveCalls,
    isFetchingPatients: state.isFetchingPatients,
    validPatients: state.validPatients,
    onSelectPatient: state.onSelectPatient,
    selectedPatient: state.selectedPatient,
  }));

  console.log('activeCalls', activeCalls);
  const { filters } = useFilterStore((state) => ({
    filters: state.filters,
  }));

  const { updateModalStatus } = useModalStore((state) => ({
    updateModalStatus: state.updateModalStatus,
  }));
  const [expandedRows, setExpandedRows] = useState([]);
  console.log('expandedRows', expandedRows);
  const [, setSelectedRecord] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const showModal = (record) => {
    onSelectPatient(record);
    setSelectedRecord(record);
    setIsOpen(true);
  };

  const handleExpand = (record) => {
    if (record?.isMultiPatient === true) {
      console.log('record', record);
      if (
        expandedRows.includes(
          `${record?.caseID ?? ''} ${record?.taskSid ?? ''} ${record?.patientFirstName ?? ''} ${record?.patientLastName ?? ''}`
        )
      ) {
        setExpandedRows(
          expandedRows.filter(
            (item) =>
              item !==
              `${record?.caseID ?? ''} ${record?.taskSid ?? ''} ${record?.patientFirstName ?? ''} ${record?.patientLastName ?? ''}`
          )
        );
      } else {
        setExpandedRows([
          ...expandedRows,
          `${record?.caseID ?? ''} ${record?.taskSid ?? ''} ${record?.patientFirstName ?? ''} ${record?.patientLastName ?? ''}`,
        ]);
      }
    }
  };

  const HISTORY_TABLE_COLUMNS = (showModal) => [
    {
      title: 'Start time',
      key: 'createTime',
      render: (_, record) => (
        <span>
          {record?.createTime
            ? dayjs(record?.createTime).utc().format('DD MMM YYYY HH:mm:ss')
            : ''}
        </span>
      ),
      sorter: (a, b) => dayjs(a.createTime).unix() - dayjs(b.createTime).unix(),
    },
    {
      title: 'Sitecode',
      key: 'sitecode',
      sorter: (a, b) =>
        parseFieldSetup(a.sitecode)
          ?.charAt(0)
          ?.localeCompare(parseFieldSetup(b.sitecode)?.charAt(0)) ?? -1,
      sortDirections: ['ascend', 'descend'],
      render: (_, record) => <span>{parseFieldSetup(record?.sitecode)}</span>,
    },
    {
      title: 'Patient name',
      key: 'patientFullName',
      render: (_, record) => (
        <span>{`${record?.patientFirstName ? ' ' + parseFieldSetup(record.patientFirstName) : ''}${record?.patientLastName ? ' ' + parseFieldSetup(record.patientLastName) : ''}`}</span>
      ),
      sorter: (a, b) =>
        parseFieldSetup(a.patientFirstName)
          ?.charAt(0)
          ?.localeCompare(parseFieldSetup(b.patientFirstName)?.charAt(0)) ?? -1,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'DOB',
      key: 'dob',
      width: 120,
      render: (_, record) => (
        <span>
          {record?.DOB ? dayjs(record?.DOB).utc().format('DD MMM YYYY') : ''}
        </span>
      ),
    },
    {
      title: 'From number',
      key: 'PHNumber',
      width: 180,
      render: (_, record) =>
        record?.PHNumber && parseFieldSetup(record?.PHNumber) ? (
          <Text copyable>{parseFieldSetup(record.PHNumber)}</Text>
        ) : (
          <span>{parseFieldSetup(record?.PHNumber)}</span>
        ),
    },
    {
      title: 'Doctor name',
      key: 'DRName',
      render: (_, record) => <span>{parseFieldSetup(record?.DRName)}</span>,
    },
    {
      title: 'Urgency',
      key: 'callType',
      width: 80,
      render: (_, record) => {
        const callType = parseFieldSetup(record?.callType);
        return (
          <span>
            {callType === 'Urgent' ? (
              <Tag color="red">Urgent</Tag>
            ) : (
              callType && <Tag color="green">{callType ?? ''}</Tag>
            )}
          </span>
        );
      },
    },
    {
      title: 'Call Type',
      key: 'callVideoVoiceType',
      width: 100,
      render: (_, record) => {
        const validCallType = record?.callSid && record?.taskSid;
        const isVoiceCall = record.callSid !== record.taskSid;
        return (
          <span>
            {validCallType &&
              (isVoiceCall ? (
                <Tag icon={<PhoneOutlined />} color="#4eb5b0">
                  Voice
                </Tag>
              ) : (
                <Tag icon={<VideoCameraOutlined />} color="#6f86d6">
                  Video
                </Tag>
              ))}
          </span>
        );
      },
      filters: [
        {
          text: 'Voice',
          value: 'voice',
        },
        {
          text: 'Video',
          value: 'website',
        },
      ],
      onFilter: (value, record) => {
        const validCallType = record?.callSid && record?.taskSid;
        const isVoiceCall = record.callSid !== record.taskSid;
        if (validCallType && value === 'voice' && isVoiceCall) return true;
        if (validCallType && value === 'website' && !isVoiceCall) return true;
        return false;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <Space size="small">
            <Tooltip title="Open patient form">
              <Button
                shape="circle"
                icon={<FormOutlined />}
                onClick={() => handlePatientIconClick(record)}
                size="small"
              />
            </Tooltip>
            <Tooltip title="Link to patient">
              <Button
                shape="circle"
                icon={<LinkOutlined />}
                onClick={() => showModal(record)}
                size="small"
              />
            </Tooltip>
            <Dropdown menu={menuProps}>
              <Button size="small" disabled>
                <Space>
                  Actions
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        );
      },
      fixed: 'right',
    },
  ];

  const ACTIVE_TABLE_COLUMNS = [
    {
      title: 'Start time',
      key: 'startTime',
      width: 200,
      render: (_, record) => (
        <span>
          {record?.startTime
            ? dayjs(record?.startTime).utc().format('DD MMM YYYY HH:mm:ss')
            : ''}
        </span>
      ),
    },
    {
      title: 'Agent name',
      key: 'agentName',
      dataIndex: 'agentName',
      width: 150,
    },
    {
      title: 'Patient name',
      key: 'patientFullName',
      render: (_, record) => (
        <span>{`${record?.patientFirstName ? ' ' + parseFieldSetup(record.patientFirstName) : ''}${record?.patientLastName ? ' ' + parseFieldSetup(record.patientLastName) : ''}`}</span>
      ),
      width: 200,
    },
    {
      title: 'DOB',
      key: 'dob',
      dataIndex: 'dob',
      width: 120,
      render: (_, record) => (
        <span>
          {record?.dob ? dayjs(record?.dob).utc().format('DD MMM YYYY') : ''}
        </span>
      ),
    },
    {
      title: 'Multi patient',
      key: 'multiPatient',
      width: 110,
      render: (_, record) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div onClick={() => handleExpand(record)}>
          {record?.isMultiPatient === true && (
            <Tag icon={<DeploymentUnitOutlined />} color="blue">
              Yes
            </Tag>
          )}
          {record?.isMultiPatient === false && (
            <Tag icon={<LineOutlined />} color="#8c8c8c">
              No
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: 'Call stage',
      key: 'callStep',
      dataIndex: 'callStep',
      width: 180,
    },
    {
      title: 'Status',
      key: 'currentStatus',
      render: (_, record) => (
        <span>{translateStatus(record?.currentStatus ?? '')}</span>
      ),
      width: 130,
    },
    {
      title: 'Urgency',
      key: 'urgency',
      dataIndex: 'urgency',
      width: 80,
      render: (_, record) => {
        return (
          <span>
            {record.urgency === 'Urgent' ? (
              <Tag color="red">Urgent</Tag>
            ) : (
              record.urgency && <Tag color="green">{record.urgency ?? ''}</Tag>
            )}
          </span>
        );
      },
    },
    {
      title: 'Call Type',
      key: 'callType',
      width: 100,
      render: (_, record) => (
        <>
          {record?.callType === 'voice' && (
            <Tag icon={<PhoneOutlined />} color="#4eb5b0">
              Voice
            </Tag>
          )}
          {record?.callType === 'video' && (
            <Tag icon={<VideoCameraOutlined />} color="#6f86d6">
              Video
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Outcome',
      key: 'outcome',
      dataIndex: 'outcome',
      ellipsis: true,
      width: 500,
    },
  ];
  const handleMenuClick = (e) => {
    console.log('click', e);
  };

  const handlePatientIconClick = (record) => {
    onSelectPatient(record);
    updateModalStatus({ open: true, type: MODAL_TYPES.PATIENT_FORM });
  };

  const items = [];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  useEffect(() => {
    if (filters.callsType === 'active') {
      fetchActiveCalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.callsType]);

  return filters?.callsType !== 'active' ? (
    <>
      <Divider>History Calls</Divider>
      <Table
        bordered
        size="small"
        rowKey={(record) => `${record.caseID} ${record.callSid}`}
        onRow={(record, index) => ({
          style: {
            background: index % 2 === 0 ? 'default' : '#FCFCFC',
          },
        })}
        loading={isFetchingPatients}
        dataSource={validPatients}
        columns={HISTORY_TABLE_COLUMNS(showModal)}
        pagination={{
          pageSize: 10,
          size: 'small',
          position: ['bottomRight'],
          showSizeChanger: false,
        }}
        sticky={{
          offsetHeader: 0,
        }}
      />
      <LinkPatientModal
        isOpen={isOpen}
        messageApi={messageApi}
        userInfo={userInfo}
        setIsOpen={setIsOpen}
        selectedPatient={selectedPatient}
      />
    </>
  ) : (
    <>
      <Divider>Active Calls</Divider>
      <Table
        bordered
        size="small"
        rowKey={(record) =>
          `${record?.caseID ?? ''} ${record?.taskSid ?? ''} ${record?.patientFirstName ?? ''} ${record?.patientLastName ?? ''}`
        }
        loading={isFetchingPatients}
        dataSource={activeCalls && activeCalls?.length ? activeCalls : []}
        columns={ACTIVE_TABLE_COLUMNS}
        expandable={{
          onExpand: (_, record) => handleExpand(record),
          expandedRowKeys: expandedRows,
        }}
        pagination={{
          pageSize: 10,
          size: 'small',
          position: ['bottomRight'],
          showSizeChanger: false,
        }}
      />
      <AppointmentTable />
    </>
  );
};

export default PatientTable;

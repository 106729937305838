import React from 'react';

const ScaledLayout = ({ children, scale = 0.8 }) => {
  const style = {
    transform: `scale(${scale})`,
    transformOrigin: 'top left',
    width: `${100 / scale}%`,
    height: `${100 / scale}%`,
    overflow: 'hidden',
  };

  return <div style={style}>{children}</div>;
};

export default ScaledLayout;

import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'antd';
import { usePatientStore } from '../store';

const DataFetchManager = ({
  fetchData,
  renderData,
  FETCH_INTERVAL = 2 * 60 * 1000,
  IDLE_TIMEOUT = 10 * 60 * 1000,
}) => {
  const { setStartFetchApptInterval } = usePatientStore((state) => ({
    setStartFetchApptInterval: state.setStartFetchApptInterval,
  }));
  const fetchIntervalId = useRef(null);
  const idleTimeoutId = useRef(null);
  const [isIdle, setIsIdle] = useState(false);

  const resetIdleTimer = () => {
    clearTimeout(idleTimeoutId.current);
    idleTimeoutId.current = setTimeout(() => {
      setIsIdle(true);
    }, IDLE_TIMEOUT);
  };

  const startFetchInterval = () => {
    if (fetchIntervalId.current) {
      clearInterval(fetchIntervalId.current);
    }
    fetchAndUpdateData();
    fetchIntervalId.current = setInterval(fetchAndUpdateData, FETCH_INTERVAL);
  };

  const fetchAndUpdateData = async () => {
    if (!isIdle) {
      try {
        await fetchData();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  useEffect(() => {
    setStartFetchApptInterval(startFetchInterval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStartFetchApptInterval]);

  useEffect(() => {
    const events = ['mousedown', 'mousemove', 'keypress', 'scroll'];
    events.forEach((event) => document.addEventListener(event, resetIdleTimer));

    return () => {
      events.forEach((event) =>
        document.removeEventListener(event, resetIdleTimer)
      );
      clearInterval(fetchIntervalId.current);
      clearTimeout(idleTimeoutId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isIdle) {
      startFetchInterval();
    } else {
      fetchIntervalId.current && clearInterval(fetchIntervalId.current);
    }

    return () => {
      fetchIntervalId.current && clearInterval(fetchIntervalId.current);
      fetchIntervalId.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);

  const handleContinue = () => {
    setIsIdle(false);
  };

  return (
    <div>
      {renderData()}
      <Modal
        open={isIdle}
        title="Are you still here?"
        onOk={handleContinue}
        keyboard={false}
        maskClosable={false}
        closeIcon={null}
        footer={[
          <Button key="continue" type="primary" onClick={handleContinue}>
            Continue
          </Button>,
        ]}
      >
        <p>
          You have been inactive on this page for a while, would you like to
          continue?
        </p>
      </Modal>
    </div>
  );
};

export default DataFetchManager;

import axios from 'axios';
import { isProd } from '../constants';

export const getSitecodeList = async () => {
  const url = isProd
    ? `https://med-discrete-data.azurewebsites.net/api/getSitecodes?code=b_FD626vnQukIlvV6ZB97SQf7t_TgLhhragJPCt38gmHAzFufIuP5Q==`
    : 'https://med-discrete-data-uat.azurewebsites.net/api/getSitecodes?code=9byg-vFzC3JaPhvlh8oYMA8FrV7k4ZhDg1aoU7zS5J-TAzFuX2sfqg==';
  try {
    let res = await axios.get(url);
    return res.data;
  } catch (err) {
    console.error('err', err);
  }
};

import React from 'react';
import { Table, Divider, Tag } from 'antd';
import dayjs from 'dayjs';
import { usePatientStore } from '../../store';
import DataFetchManager from '../DataFetchManager';
import { parseFieldSetup } from '../../utils/stringUtils';

const columns = [
  {
    title: 'Created time',
    key: 'createdDateTime',
    width: 200,
    render: (_, record) => (
      <span>
        {record?.createdDateTime
          ? dayjs(record?.createdDateTime).utc().format('DD MMM YYYY HH:mm:ss')
          : ''}
      </span>
    ),
  },
  {
    title: 'Scheduled time',
    key: 'scheduleDateTime',
    width: 200,
    render: (_, record) => (
      <span>
        {record?.scheduleDateTime
          ? dayjs(record?.scheduleDateTime).utc().format('DD MMM YYYY HH:mm:ss')
          : ''}
      </span>
    ),
    defaultSortOrder: 'ascend',
    sorter: (a, b) =>
      dayjs(a.createdDateTime).unix() - dayjs(b.createdDateTime).unix(),
    // ascend | descend
  },
  {
    title: 'Client',
    key: 'client',
    dataIndex: 'client',
    width: 150,
  },
  {
    title: 'Sitecode',
    key: 'sitecode',
    dataIndex: 'sitecode',
    width: 170,
  },
  {
    title: 'Patient name',
    key: 'patientFullName',
    render: (_, record) => (
      <span>{`${record?.patientfirstName ? ' ' + parseFieldSetup(record.patientfirstName) : ''}${record?.patientlastName ? ' ' + parseFieldSetup(record.patientlastName) : ''}`}</span>
    ),
    width: 180,
  },
  {
    title: 'DOB',
    key: 'dob',
    width: 120,
    render: (_, record) => (
      <span>
        {record?.patientdob
          ? dayjs(record?.patientdob).utc().format('DD MMM YYYY')
          : ''}
      </span>
    ),
  },
  {
    title: 'Phone',
    key: 'phone',
    dataIndex: 'patientmobilePhone',
    width: 140,
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    width: 180,
  },
  {
    title: 'Urgency',
    key: 'urgent',
    width: 80,
    render: (_, record) => (
      <span>
        {record?.urgent === 2 ? (
          <Tag color="red">Urgent</Tag>
        ) : (
          record?.urgent === 1 && <Tag color="green">Normal</Tag>
        )}
      </span>
    ),
  },
  {
    title: 'Appt notes',
    key: 'apptNotes',
    dataIndex: 'appointmentnote',
    ellipsis: true,
    // ellipsis: {
    //   showTitle: false,
    // },
    // width: 500,
  },
  Table.EXPAND_COLUMN,
];

const AppointmentTable = () => {
  const {
    fetchMRAppointments,
    isFetchingMRAppointments,
    patientMRAppointments,
  } = usePatientStore((state) => ({
    fetchMRAppointments: state.fetchMRAppointments,
    isFetchingMRAppointments: state.isFetchingMRAppointments,
    patientMRAppointments: state.patientMRAppointments,
  }));

  return (
    <>
      <Divider>MR Appointments</Divider>
      <DataFetchManager
        fetchData={fetchMRAppointments}
        renderData={() => (
          <Table
            bordered
            rowKey={(record) => record.appointmentguid}
            dataSource={patientMRAppointments}
            columns={columns}
            size="small"
            loading={isFetchingMRAppointments}
            pagination={{
              pageSize: 30,
              size: 'small',
              position: ['bottomRight'],
              showSizeChanger: false,
            }}
            expandable={{
              expandedRowRender: (record) => (
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  Appt notes:
                  {record.appointmentnote}
                </p>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default AppointmentTable;
